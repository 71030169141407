import React from "react";
import { Icon } from "@chakra-ui/react"
import {
  AiFillFacebook, AiFillInstagram, AiOutlinePhone
} from "react-icons/ai";

import {
  Flex,
  Box,
  Center,
  Wrap,
  WrapItem,
  Link
} from "@chakra-ui/react"

import Logo from "../ui/Logo";

import Move from "../ui/IconAnimations";

export default function Footer() {
  return (
      <Flex
        w="100%"
        maxW={{ xl: "1200px" }}
        p={{ xxl: "75px 0px", xl: "75px 50px", xs: "75px 50px" }}
      >

        <Wrap
          spacing={3}
          display={{ md: "block", sm: "block" }}
          p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "15px 0px", sm: "15px 0px" }}
          className="footer"
        >

          <WrapItem
            w={{ xxl: "35%", xl: "35%", xs: "35%", lg: "35%", md: "100%", sm: "100%" } }
            textAlign={{ sm: "center" }}
            alignItems="center"
            display={{ xxl: "block", xl: "block", xs: "block", lg: "block", md: "none", sm: "none" }}
            float="left"
          >
            <Logo
              w="250px"
            />
          </WrapItem>

          <WrapItem
            w={{ xxl: "40%", xl: "40%", xs: "40%", lg: "40%", md: "100%", sm: "100%" } }
            textAlign={{ sm: "center" }}
            display={{ xxl: "block", xl: "block", xs: "block", lg: "block", md: "block", sm: "block" }}
            p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "15px 15px 15px 8px;", sm: "15px 15px 15px 8px;" }}
            borderBottom={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "1px solid #dedede", sm: "1px solid #dedede" }}
            mb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "10px", sm: "10px" }}
            float="left"
          >

            <Center
              w={{ xxl: "20%", xl: "20%", xs: "20%", lg: "20%", md: "100%", sm: "100%" } }
              h="100%"
              float="left"
            >

              <Link href="https://www.facebook.com/agenstvo.mesterdom" target="__blank">
                <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                  <Icon
                    as={AiFillFacebook}
                    color="#3d589b"
                    w="35px"
                    h="35px"
                  />
                </Move>
              </Link>
              <Link href="https://www.instagram.com/misterdom.uz?r=nametag" target="__blank">
                <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                  <Icon
                    as={AiFillInstagram}
                    color="#c22984"
                    w="35px"
                    h="35px"
                  />
                </Move>
              </Link>

            </Center>

            <Box
              w={{ xxl: "80%", xl: "80%", xs: "80%", lg: "80%", md: "100%", sm: "100%" } }
              fontSize={{ xxl: "14px", xl: "11px", xs: "11px" }}
              textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "left", md: "center", sm: "center" }}
            >
              Copyright 2021 ООО "MISTER DOM" <br />
              Копирование материалов только с согласия владельцев
            </Box>

          </WrapItem>

          <WrapItem
            w={{ xxl: "20%", xl: "20%", xs: "20%", lg: "20%", md: "100%", sm: "100%" } }
            textAlign={{ sm: "center" }}
            display={{ xxl: "block", xl: "block", xs: "block", lg: "block", md: "block", sm: "block" }}
            color="#83898c"
            fontSize={{ xxl: "13px", xl: "11px", xs: "11px" }}
            float="left"
            p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "0px 15px 15px;", sm: "0px 15px 15px;" }}
          >
            Работаем: Пн-Пт с 09:00 до 19:00
            <Link
              href="tel:+998951440060"
              w="100%"
              float="left"
              color="#ff0000"
              fontSize={{ xxl: "20px", xl: "17px", xs: "16px" }}
              fontWeight={800}
            >

              <Move
                x={20}
              >
                <Icon
                  as={AiOutlinePhone}
                  w="28px"
                  h="28px"
                  fill="#ffffff"
                  bg="#ff0000"
                  p="5px 3px"
                  borderRadius="50%"
                />
              </Move> +998 (95) 144-00-60

            </Link>
          </WrapItem>

        </Wrap>

      </Flex>

  );
}
