import React, { useState } from "react";

import { useSpring, animated, config } from 'react-spring'

import {
  Flex,
  VStack,
  Stack,
  Box,
  Heading,
  Text,
  Badge,
  Link,
  Button,
  Center
} from "@chakra-ui/react";

import Header from "../sections/Header";

export default function Slider() {

  const [hover, setHover] = useState(false);
  const hoverState = useSpring({
    transform: hover
      ? 'translate3d(0px, -12px, 0px) scale(1.03)'
      : 'translate3d(0px, 0px, 0px) scale(1)',
    config: config.wobbly
  });

  return (
    <Flex
      align="center"
      bg="URL('./images/slider.jpg')"
      w="100%"
      p={{ xxl: "0px", xl: "0px 50px", xs: "0px 50px" }}
    >

      <VStack
        maxW={{ xl: "1200px" }}
        w="100%"
        m="0px auto"
      >

        <Header />

        <Box
          mt="45px"
          textAlign="center"
        >

          <Box>

            <Heading
              as="h2"
              textTransform="uppercase"
              fontSize="32px"
            >
              Услуги в сфере недвижимости в Узбекистане от специалистов с опытом работы более 15 лет
            </Heading>

            <Text
              color={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ffffff", sm: "#ffffff" }}
              fontWeight={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "bold", sm: "bold" }}
            >
              Покупка и продажа недвижимости, сопровождение сделки, помощь в оформлении ипотеки и другие услуги
            </Text>

          </Box>

          <Center h={{ xxl: "800px", xl: "800px", xs: "800px", lg: "800px", md: "400px", sm: "400px" }}>

            <Link
              href="#feedback"
            >

              <Button
                bg="#ffffff8c"
                color="#ff0000"
                p={{ xxl: "30px 50px", xl: "30px 50px", xs: "30px 50px", lg: "30px 50px", md: "0px 30px", sm: "0px 30px" }}
                fontFamily="Century Gothic Bold"
                display="flex"
                verticalAlign="middle"
              >
                ПОДОБРАТЬ НЕДВИЖИМОСТЬ
              </Button>

            </Link>

          </Center>

        </Box>

        <Stack
          direction={["column", "row"]}
          spacing="24px"
          maxW={{ xl: "1200px" }}
          display="block"
          pos="relative"
        >

          <Box
            w={{ xxl: "40%", xl: "40%", xs: "40%", lg: "100%", md: "100%", sm: "100%"}}
            mr={{ xxl: "3%", xl: "3%", xs: "3%", lg: "0px", md: "0px", sm: "0px"}}
            p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px 50px", md: "0px 50px", sm: "0px 50px"}}
            float="left"
            pos="relative"
            zIndex={1}
          >

            <Heading
              as="h2"
              textTransform="uppercase"
              fontSize={{ xxl: "32px", xl: "32px", xs: "32px", lg: "32px", md: "24px", sm: "24px" }}
              lineHeight="1.6"
              textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "center", md: "center", sm: "center"}}
              color={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "#ffffff", sm: "#ffffff" }}
            >
              Качественные услуги
              в сфере недвижимости
              - наша работа
            </Heading>

            <Text
              m="45px 0px"
            >

              ООО "MISTER DOM" - высоко профессиональное агентство
              недвижимости, с опытом работы с 2005 года, успешной историей,
              стабильным положением на рынке, устоявшейся репутацией среди
              партнеров и клиентов, которой мы очень дорожим.

            </Text>

            <Text
              mt="25px"
            >
              Обратившись в нашу компанию, Вы получите бесплатную
              квалифицированную консультацию специалистов. Они помогут
              Вам выбрать самый оптимальный вариант для покупки, который в
              наибольшей степени соответствует Вашим пожеланиям.

              <Badge
                w="100%"
                bg="none"
                fontSize="16px"
                mt="25px"
              >
                С уважением к нашим клиентам!
              </Badge>

            </Text>


            <Link
              href="#feedback"
              float="left"
              m="25px 0px"
              w="100%"
              textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "center", md: "center", sm: "center" }}
            >

              <Button
                bg="none"
                fontFamily="Century Gothic Bold"
                color="#ff0000"
                border="2px solid #ff0000"
                p={{ xxl: "30px 50px", xl: "30px 50px", xs: "30px 50px", lg: "30px 50px", md: "0px 30px", sm: "0px 30px" }}
                display="flex"
                verticalAlign="middle"
              >
                КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА
              </Button>

            </Link>

          </Box>

          <Box
            w={{ xxl: "47%", xl: "47%", xs: "47%", lg: "100%", md: "100%", sm: "100%" }}
            float="left"
            pos={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "absolute", md: "absolute", sm: "absolute" }}
            zIndex={0}
            opacity={{ xxl: "1", xl: "1", xs: "1", lg: "0.1", md: "0.1", sm: "0.1" }}
            top={{ xxl: "0px", xl: "0px", xs: "0px", lg: "50%", md: "50%", sm: "50%" }}
            left={{ xxl: "0px", xl: "0px", xs: "0px", lg: "10%", md: "10%", sm: "10%" }}
          >

            <Heading
              as="h4"
              className="vertical-orientation"
              float="left"
              color="#ff0000"
              textTransform="uppercase"
              fontSize="36px"
              mr="5%"
              maxH="200px"
            >
              Гарантия
              качества
            </Heading>

            <animated.div
              style={{
                width: '100%',
                ...hoverState
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >

              <Center
                h="200px"
                bg="#ff0000"
                borderRadius="50%"
                textAlign="center"
                color="#ffffff"
                mt="0px"
                w="200px"
              >

                <Heading
                  as="h4"
                >
                  15 лет

                  <Text
                    fontSize="14px"
                    fontWeignt="300"
                    p="0px 15px"
                    maxW="230px"
                  >
                    Опыта работы в сфере
                    недвижимости
                  </Text>

                </Heading>

              </Center>

            </animated.div>

          </Box>

        </Stack>

      </VStack>

    </Flex>
  );
}
