import React from "react";

import {
  Text,
  Link,
  Center,
  Icon,
  Box
} from "@chakra-ui/react";

import { AiOutlineEnvironment } from "react-icons/ai";

import Move from "../ui/IconAnimations";

export default function ModalWindow( props: any ) {

  return ( <>

      <Center
        w="20%"
        float="left"
        h={{ xxl: "40px", xl: "40px", xs: "40px", lg: "40px", md: "40px", sm: "40px" }}
      >

        <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >

          <Link
            href={props.link}
            target="__blank"
          >
            <Icon
              as={AiOutlineEnvironment}
              fontSize="30px"
              color="#ff0000"
              borderRadius="50%"
              p="5px"
              bg="#ffffff54"
              cursor="pointer"
            />

          </Link>

        </Move>

      </Center>

      <Box
        flex="1"
        w="100%"
        p={{ xxl: "0px", xl: "0px", xs: "0px", md: "5px 0px 12px", sm: "5px 0px 12px" }}
      >

        <Link
          href={props.link}
          fontSize={{ xxl: "14px", xl: "11.5px", xs: "10.8px" }}
          mt={{ xxl: props.margin, xl: props.margin, xs: props.margin, md: "0px", sm: "0px" }}
          target="__blank"
        >
          {props.address}
        </Link>

      </Box>
    </>
  );
}
