import React from "react";
import { Icon } from "@chakra-ui/react"

import {
  AiOutlineExclamation, AiFillNotification
} from "react-icons/ai";

import { ReactComponent as Call } from "assets/icons/call.svg";
import { ReactComponent as Consultation } from "assets/icons/consultation.svg";
import { ReactComponent as Departure } from "assets/icons/departure.svg";
import { ReactComponent as Advertisement } from "assets/icons/advertisement.svg";
import { ReactComponent as Clients } from "assets/icons/clients.svg";
import { ReactComponent as Deal } from "assets/icons/deal.svg";

import {
  Flex,
  VStack,
  Heading,
  Box,
  SimpleGrid,
  Badge
} from "@chakra-ui/react"

import Move from "../ui/IconAnimations";

const Step = ( { icon, title, step, ...rest } : any  ) => {

  return (

    <Box
      bg="#ffffff59"
      borderRadius="20px"
      p="20px"
      align="center"
      textAlign="center"
    >

      <Move
        y={20}
      >

        <Icon
          as={icon}
          fontSize="45px"
          mb="15px"
          fill="#ff0000"
        />

      </Move>

      <Heading
        as="h6"
        fontSize="14px"
        color="#8a8a8a"
        lineHeight="1.5"
        m="0px auto"
      >
        {title}
      </Heading>

      <Badge
        bg="none"
        fontSize={24}
        color="#ff0000"
        fontWeight="bold"
        mt={15}
      >
        {step}
      </Badge>

    </Box>
  )

};

export default function AboutUs() {

  return (
    <>

    <Flex
      id="aboutUs"
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      bg="URL('./images/aboutUs.jpg')"
      bgRepeat="no-repeat"
      w="100%"
      bgSize="cover"
      p={{ xxl: "25px 0px", xl: "25px 50px", xs: "25px 50px", lg: "50px", md: "50px", sm: "50px" }}
    >

      <VStack
        maxW={{ xl: "1200px" }}
        w="100%"
        m="0px auto"
        pos="relative"
        textAlign="center"
      >

      <Box
        w="100%"
        pos="relative"
        m="50px 0px"
      >

          <Heading
            as="h2"
            textTransform="uppercase"
            fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
          >
            <Icon as={AiFillNotification}
                  bg="#1f191a"
                  color="#ffffff"
                  borderRadius="5px"
                  p={ { xxl: "5px 10px", xl: "5px 10px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                  m={ { xxl: "0px 0px 8px", xl: "0px 0px 8px", xs: "0px 0px 8px", lg: "0px 0px 8px", md: "0px 0px 4px", sm: "0px 0px 4px" } }
            />
            Как мы работаем компания <br /> OOO "MISTER DOM"
          </Heading>

      </Box>

      <Box
        w="100%"
        pos="relative"
      >

        <SimpleGrid columns={{ xxl: 3, xl: 3, xs: 3, lg: 3, md: 1, sm: 1 }} spacing={{ xxl: "175px", xl: "175px", xs: "175px", lg: "175px", md: "25px", sm: "25px" }}>

            <Step
              icon={Call}
              title="Вы звоните к нам и оставляйте заявку"
              step="01"
            />

          <Step
            icon={Consultation}
            title="Консультации по всем вопросам связанными с недвижимостью"
            step="02"
          />

          <Step
            icon={Departure}
            title="Выезжаем к вам для изучения объекта недвижимости"
            step="03"
          />

          <Step
            icon={Advertisement}
            title="Размещение информации о продаже квартиры на всех возможных платформах, социальных сетях и т.д."
            step="04"
          />

          <Step
            icon={Clients}
            title="Находим потенциального покупателя"
            step="05"
          />

          <Step
            icon={Deal}
            title="Успешно проводим сделку!"
            step="06"
          />

       </SimpleGrid>

        <Heading
          pos={{ xxl: "absolute", xl: "absolute", xs: "absolute", lg: "absolute", md: "relative", sm: "relative" }}
          top="50%"
          left={{ xxl: "50%", xl: "50%", xs: "50%", lg: "50%", md: "0px", sm: "0px" }}
          mt={{ xxl: "-45px", xl: "-45px", xs: "-45px", lg: "-45px", md: "30px", sm: "30px" }}
          ml={{ xxl: "-320px", xl: "-320px", xs: "-320px", lg: "-320px", md: "0px", sm: "0px" }}
          bg="#ffffff59"
          textTransform="uppercase"
          fontSize={20}
          p="5px 25px"
          borderRadius="5px"
        >

          <Icon
            as={AiOutlineExclamation}
            fontSize="20px"
            m="10px 0px 15px"
            color="#ffffff"
            bg="#ff0000"
            borderRadius="50%"

          /> Наши услуги оплачиваются <Badge
          bg="none"
          color="#ff0000"
          fontSize="20px"
          p="0px"
          mt="-6px"
          >
            только по результату
        </Badge>

        </Heading>

      </Box>

      </VStack>

    </Flex>

    </>
  );
}
